@tailwind utilities;
@layer utilities {
    .scrollbar::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    .scrollbar::-webkit-scrollbar-track {
        @apply bg-neutral-300 dark:bg-zinc-900;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        @apply rounded-full bg-neutral-400 transition delay-150 duration-300 dark:bg-zinc-700;
    }
}

.resize-style::-webkit-resizer {
    @apply hidden;
}
